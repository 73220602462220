import { WalletAdapter } from "@solana/wallet-adapter-base";
import { FC } from "react";
import { SelectItem } from "@/ui/select";
import { Avatar, AvatarImage } from "@/ui/avatar";
import { Label } from "@/ui/label";

export const WalletListItem: FC<{ adapter: WalletAdapter }> = (props) => {
  const { name, icon } = props?.adapter || { name: undefined, icon: undefined };
  if (!name) return <></>;
  return (
    <SelectItem value={name}>
      <div className="flex items-center space-x-2">
        <Avatar id="avatar" className="h-5 w-5">
          <AvatarImage src={icon} alt={name} />
        </Avatar>
        <Label htmlFor="avatar">{name}</Label>
      </div>
    </SelectItem>
  );
};
