import { buildServerConfig } from "@/lib/constants";
import { requestClient } from "./requestClient";

export const userRequestClient = () => {
  const userConfig = buildServerConfig();
  userConfig.basePath += "/users";

  const baseClient = requestClient(userConfig);

  const getUserKillBalance = async (authToken: string) => {
    return await baseClient.get<UserBalanceResponse>({
      path: "/balance",
      headers: {
        Authorization: "Bearer " + authToken,
      },
    });
  };

  return {
    getUserKillBalance,
  };
};
