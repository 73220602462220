import { ReloadIcon } from "@radix-ui/react-icons";
import React, { FC, useCallback, useEffect, useState } from "react";
import { Label } from "@/ui/label";
import { numberFormatter } from "@/lib/utils";
import { siteClient } from "@/lib/constants";
import { toast } from "sonner";
import { getErrorMessage } from "@/lib/errors";

export const Jackpot: FC = () => {
  const [jackpot, setJackpot] = useState(250000.0);
  const [noChangeCount, setNoChangeCount] = useState(1);

  const handleGetJackpot = useCallback(
    async (delay: number) => {
      const jackpotJson = await siteClient.getJackpot();
      if (jackpotJson.status === 200) {
        if (jackpotJson.jackpot === jackpot) {
          setTimeout(() => setNoChangeCount(noChangeCount + 1), 2000 * delay);
        } else setJackpot(jackpotJson.jackpot);
      } else {
        toast.error(getErrorMessage(jackpotJson.error));
      }
    },
    [noChangeCount, jackpot]
  );

  useEffect(() => {
    handleGetJackpot(noChangeCount);

    return () => { };
  }, [noChangeCount, handleGetJackpot]);

  return (
    <div className="flex flex-row justify-center mt-2 space-x-2">
      <ReloadIcon
        width={12}
        height={12}
        className="animate-spin font-xs"
      ></ReloadIcon>
      <Label className="font-xs">
        {numberFormatter.format(jackpot)} <code>$SOLP</code>
      </Label>
    </div>
  );
};
