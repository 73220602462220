import { buildServerConfig } from "@/lib/constants";
import { requestClient } from "./requestClient";

export const spinRequestClient = () => {
  const spinConfig = buildServerConfig();
  spinConfig.basePath += "/game";

  const baseClient = requestClient(spinConfig);

  const initializeSpin = async (authToken: string) => {
    return await baseClient.get<InitializeSpinResponse>({
      path: "/spin",
      headers: {
        Authorization: `Bearer ${authToken}`,
      },
    });
  };

  const startSpin = async (
    authToken: string,
    spinId: number,
    totalTokensBet: number,
    linesBet: number,
    initializationVector: string,
    secretKey: string
  ) => {
    return await baseClient.post<StartSpinResponse>({
      path: "/spin",
      body: JSON.stringify({
        spinId,
        totalTokensBet,
        linesBet,
        initializationVector,
        secretKey,
      }),
      headers: {
        Authorization: "Bearer " + authToken,
      },
    });
  };

  const finalizeSpin = async (
    authToken: string,
    id: number,
    signedTransaction: string
  ) => {
    return await baseClient.put<FinalizeSpinResponse>({
      path: "/spin",
      body: JSON.stringify({ spinId: id, signedTransaction }),
      headers: {
        Authorization: "Bearer " + authToken,
      },
    });
  };

  return {
    initializeSpin,
    startSpin,
    finalizeSpin,
  };
};
