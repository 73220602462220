import { IMAGE_KEY, LAYOUT_IMAGE_MAP } from "./slotImages/slot.image-data";

const IMAGE_SIZE = 96;
const fixedWidth = `w-[${IMAGE_SIZE}px]`;
const fixedHeight = `h-[${IMAGE_SIZE}px]`;
const fixedSquare = fixedWidth + " " + fixedHeight;

const getImageSourceFromKey = (key: IMAGE_KEY) => {
  let result;
  if (key && Object.keys(LAYOUT_IMAGE_MAP).includes(key)) {
    result = LAYOUT_IMAGE_MAP[key];
  }
  return result;
};

const validateLayoutProps = (layouts: { [key: string]: string }) => {
  let result = false;
  if (layouts && typeof layouts === "object") {
    let countedKeys = 0;
    let validKeys = 0;
    for (const [, value] of Object.entries(layouts)) {
      if (value && value.length > 0) {
        validKeys++;
      }
      countedKeys++;
    }
    if (validKeys >= 3 && countedKeys === validKeys) result = true;
  }
  return result;
};

export { IMAGE_SIZE, getImageSourceFromKey, validateLayoutProps, fixedSquare };
