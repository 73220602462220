import React, { FC, useEffect, useState } from "react";
import { Button } from "@/ui/button";
import { useGame } from "@/context/game/game";
import { ReloadIcon } from "@radix-ui/react-icons";
import { useAccount } from "@/context/account/account";

interface SpinButtonProps {
  onSpin: () => void;
}
export const SpinButton: FC<SpinButtonProps> = ({ onSpin }) => {
  const { gameStatus, settings } = useGame();
  const { spTokenBalance } = useAccount();

  const [buttonText, setButtonText] = useState("Spin");

  const isSpinning = gameStatus === "starting" || gameStatus === "confirming";
  useEffect(() => {
    if (gameStatus === "starting" || gameStatus === "stopped") {
      setButtonText("Spin");
    } else if (gameStatus === "errored") {
      setButtonText("Try Again");
    } else if (gameStatus === "confirming") {
      setButtonText("Confirming");
    }
  }, [gameStatus]);

  const disabled =
    !settings ||
    !settings.minimumBet ||
    spTokenBalance?.isLessThan(settings.minimumBet);
  return (
    <Button
      onClick={onSpin}
      className="w-full"
      disabled={isSpinning || disabled}
    >
      {isSpinning && <ReloadIcon className="animate-spin me-2" />} {buttonText}
    </Button>
  );
};
