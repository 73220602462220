import React, { ChangeEvent, FC, useCallback, useState } from "react";
import { cn } from "../lib/utils";
import { Button } from "@/ui/button";
import { Card, CardContent, CardFooter } from "@/ui/card";
import { WalletConnectButton } from "./wallet/wallet.button";
import { Input } from "@/ui/input";
import { Slider } from "@/ui/slider";
import { Label } from "@/ui/label";
import { SpinButton } from "./panelComponents/spinButton";
import { useGame, useGameDispatch } from "@/context/game";
import { useAccount } from "@/context/account/account";
import { ClassNameValue } from "tailwind-merge";
import { InfoCircledIcon } from "@radix-ui/react-icons";
import { Separator } from "@/ui/separator";
import { Popover, PopoverTrigger, PopoverContent } from "@/ui/popover";
import { AlertDialog } from "@/ui/alert-dialog";
import { Alert, AlertDescription, AlertTitle } from "@/ui/alert";

type CardProps = React.ComponentProps<typeof Card> & {
  className?: ClassNameValue;
};

const SP_DECIMALS = 6;
const SP_NORM = Math.pow(10, SP_DECIMALS);
const normalizeKillBet = (amount: number) => {
  return Math.floor(amount * SP_NORM) / SP_NORM;
};

export const GamingPanel: FC<CardProps> = ({
  className,
  ...props
}: CardProps) => {
  const { settings, currentBet } = useGame();
  const { placeBet, updateCurrentBet } = useGameDispatch();
  const { spTokenBalance } = useAccount();
  const [uiValue, setUiValue] = useState(currentBet.totalTokensBet.toFixed(6));

  // const handleTogglePress = useCallback(
  //   (value: string) => {
  //     if (!value) return;

  //     if (spTokenBalance) {
  //       const nextBalance = normalizeKillBet(
  //         spTokenBalance.toNumber() * (parseInt(value) / 100)
  //       );
  //       updateCurrentBet({
  //         ...currentBet,
  //         totalTokensBet: nextBalance,
  //       });
  //     }
  //   },
  //   [spTokenBalance, updateCurrentBet]
  // );

  const spin = useCallback(() => {
    placeBet();
  }, [placeBet]);

  const handleValueChange = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      e.preventDefault();
      const nextValue = e.target.value;
      if (/^[+-]?([0-9]+([.][0-9]*)?|[.][0-9]+)$/.test(nextValue)) {
        updateCurrentBet({
          ...currentBet,
          totalTokensBet: parseFloat(nextValue),
        });
        setUiValue(nextValue);
      }
    },
    [currentBet]
  );

  const handleBetlineChange = useCallback(
    (value: number[]) => {
      let extractedValue = 1;
      if (
        !value ||
        value.length === 0 ||
        typeof value[0] !== "number" ||
        value[0] < 1 ||
        value[0] > 5
      ) {
        extractedValue = 1;
      } else {
        extractedValue = value[0];
      }
      const { linesBet } = currentBet;
      const newBetAmount =
        normalizeKillBet(currentBet.totalTokensBet / linesBet) * extractedValue;

      setUiValue(newBetAmount.toFixed(6));
      updateCurrentBet({
        totalTokensBet: newBetAmount,
        linesBet: extractedValue,
      });
    },
    [currentBet]
  );

  return (
    <Card className={cn("p-2 min-w-[380px]", className)} {...props}>
      <CardContent className="grid gap-2 p-6">
        <div className="flex w-full">
          {/* <Alert>
            <AlertTitle>MAINTENANCE</AlertTitle>
            <AlertDescription>
              Updating payout mutlipliers & testing, site will be up in 45
              minutes.
            </AlertDescription>
          </Alert> */}
        </div>
        <div className="mb-2">
          <div className="flex w-full max-w-sm items-center justify-between text-muted-foreground pt-1 ">
            <p className="text-[11px]">
              Min/Max bet: {settings?.minimumBet}/{settings?.maximumBet}
            </p>
            <span className="text-[11px]">
              {spTokenBalance ? spTokenBalance.toString() : 0} $SOLP
            </span>
          </div>
          <div className="flex w-full max-w-sm items-center">
            <Input
              onChange={handleValueChange}
              value={uiValue}
              id="betValue"
              placeholder="1000.0"
              className="bg-slate-700 rounded-[0] text-right font-medium focus-visible:ring-0"
            />
            <Button disabled className="bg-slate-700 rounded-[0] text-white">
              $SOLP
            </Button>
          </div>
          {/* <div className="flex w-full max-w-sm justify-between items-center text-muted-foreground pt-1 ">
            <ToggleGroup
              className=""
              type="single"
              onValueChange={handleTogglePress}
              defaultValue="100"
            >
              <ToggleGroupItem
                className="bg-slate-700 rounded-[0] text-[9px] h-6 w-8 rounded-l"
                value="50"
              >
                half
              </ToggleGroupItem>
              <ToggleGroupItem
                className="bg-slate-700 rounded-[0] text-[9px] h-6 w-8 rounded-r"
                value="100"
              >
                max
              </ToggleGroupItem>
            </ToggleGroup>
            
          </div> */}
        </div>
        <div className="space-y-4">
          <div className="flex align-center gap-3">
            <Label htmlFor="betLineSlider">
              Bet Lines: {currentBet.linesBet}x
            </Label>
            <Popover>
              <PopoverTrigger>
                <InfoCircledIcon className="" />
              </PopoverTrigger>
              <PopoverContent>
                <p className="text-muted-foreground text-[11px]">
                  Determins the number of bet lines active during each play.
                </p>
                <Separator className="my-2" />
                <div className="max-w-36 mx-auto">
                  <svg
                    viewBox="0 0 671 820"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <line
                      x1="271.354"
                      y1="259.646"
                      x2="371.354"
                      y2="359.646"
                      stroke="white"
                    />
                    <line
                      x1="470.646"
                      y1="359.646"
                      x2="570.646"
                      y2="259.646"
                      stroke="white"
                    />
                    <line
                      x1="270.646"
                      y1="559.646"
                      x2="370.646"
                      y2="459.646"
                      stroke="white"
                    />
                    <line
                      x1="471.354"
                      y1="459.646"
                      x2="571.354"
                      y2="559.646"
                      stroke="white"
                    />
                    <rect
                      x="171.5"
                      y="160.5"
                      width="99"
                      height="99"
                      rx="5.5"
                      stroke="white"
                    />
                    <rect
                      x="371.5"
                      y="160.5"
                      width="99"
                      height="99"
                      rx="5.5"
                      stroke="white"
                    />
                    <line
                      x1="271"
                      y1="209.5"
                      x2="371"
                      y2="209.5"
                      stroke="white"
                    />
                    <rect
                      x="571.5"
                      y="160.5"
                      width="99"
                      height="99"
                      rx="5.5"
                      stroke="white"
                    />
                    <line
                      x1="471"
                      y1="209.5"
                      x2="571"
                      y2="209.5"
                      stroke="white"
                    />
                    <line
                      x1="71"
                      y1="209.5"
                      x2="171"
                      y2="209.5"
                      stroke="white"
                    />
                    <rect
                      x="171.5"
                      y="360.5"
                      width="99"
                      height="99"
                      rx="5.5"
                      stroke="white"
                    />
                    <rect
                      x="371.5"
                      y="360.5"
                      width="99"
                      height="99"
                      rx="5.5"
                      stroke="white"
                    />
                    <line
                      x1="271"
                      y1="409.5"
                      x2="371"
                      y2="409.5"
                      stroke="white"
                    />
                    <rect
                      x="571.5"
                      y="360.5"
                      width="99"
                      height="99"
                      rx="5.5"
                      stroke="white"
                    />
                    <line
                      x1="471"
                      y1="409.5"
                      x2="571"
                      y2="409.5"
                      stroke="white"
                    />
                    <line
                      x1="71"
                      y1="409.5"
                      x2="171"
                      y2="409.5"
                      stroke="white"
                    />
                    <rect
                      x="171.5"
                      y="560.5"
                      width="99"
                      height="99"
                      rx="5.5"
                      stroke="white"
                    />
                    <rect
                      x="371.5"
                      y="560.5"
                      width="99"
                      height="99"
                      rx="5.5"
                      stroke="white"
                    />
                    <line
                      x1="271"
                      y1="609.5"
                      x2="371"
                      y2="609.5"
                      stroke="white"
                    />
                    <rect
                      x="571.5"
                      y="560.5"
                      width="99"
                      height="99"
                      rx="5.5"
                      stroke="white"
                    />
                    <line
                      x1="471"
                      y1="609.5"
                      x2="571"
                      y2="609.5"
                      stroke="white"
                    />
                    <line
                      x1="71"
                      y1="609.5"
                      x2="171"
                      y2="609.5"
                      stroke="white"
                    />
                    <line
                      x1="100.354"
                      y1="88.6464"
                      x2="171.064"
                      y2="159.357"
                      stroke="white"
                    />
                    <line
                      x1="99.6464"
                      y1="730.357"
                      x2="170.357"
                      y2="659.646"
                      stroke="white"
                    />
                    <path
                      d="M27.3068 386.455V433H21.6705V392.364H21.3977L10.0341 399.909V394.182L21.6705 386.455H27.3068Z"
                      fill="white"
                    />
                    <path
                      d="M5.94318 233V228.909L21.3068 212.091C23.1098 210.121 24.5947 208.409 25.7614 206.955C26.928 205.485 27.7917 204.106 28.3523 202.818C28.928 201.515 29.2159 200.152 29.2159 198.727C29.2159 197.091 28.822 195.674 28.0341 194.477C27.2614 193.28 26.2008 192.356 24.8523 191.705C23.5038 191.053 21.9886 190.727 20.3068 190.727C18.5189 190.727 16.9583 191.098 15.625 191.841C14.3068 192.568 13.2841 193.591 12.5568 194.909C11.8447 196.227 11.4886 197.773 11.4886 199.545H6.125C6.125 196.818 6.75379 194.424 8.01136 192.364C9.26894 190.303 10.9811 188.697 13.1477 187.545C15.3295 186.394 17.7765 185.818 20.4886 185.818C23.2159 185.818 25.6326 186.394 27.7386 187.545C29.8447 188.697 31.4962 190.25 32.6932 192.205C33.8902 194.159 34.4886 196.333 34.4886 198.727C34.4886 200.439 34.178 202.114 33.5568 203.75C32.9508 205.371 31.8902 207.182 30.375 209.182C28.875 211.167 26.7917 213.591 24.125 216.455L13.6705 227.636V228H35.3068V233H5.94318Z"
                      fill="white"
                    />
                    <path
                      d="M33.196 52.4545V47.8182L53.6506 15.4545H57.0142V22.6364H54.7415L39.2869 47.0909V47.4545H66.8324V52.4545H33.196ZM55.1051 62V51.0455V48.8864V15.4545H60.4688V62H55.1051Z"
                      fill="white"
                    />
                    <path
                      d="M20.6705 634.636C17.6705 634.636 14.9962 634.121 12.6477 633.091C10.3144 632.061 8.45833 630.629 7.07955 628.795C5.71591 626.947 4.97348 624.803 4.85227 622.364H10.5795C10.7008 623.864 11.2159 625.159 12.125 626.25C13.0341 627.326 14.2235 628.159 15.6932 628.75C17.1629 629.341 18.7917 629.636 20.5795 629.636C22.5795 629.636 24.3523 629.288 25.8977 628.591C27.4432 627.894 28.6553 626.924 29.5341 625.682C30.4129 624.439 30.8523 623 30.8523 621.364C30.8523 619.652 30.428 618.144 29.5795 616.841C28.7311 615.523 27.4886 614.492 25.8523 613.75C24.2159 613.008 22.2159 612.636 19.8523 612.636H16.125V607.636H19.8523C21.7008 607.636 23.322 607.303 24.7159 606.636C26.125 605.97 27.2235 605.03 28.0114 603.818C28.8144 602.606 29.2159 601.182 29.2159 599.545C29.2159 597.97 28.8674 596.598 28.1705 595.432C27.4735 594.265 26.4886 593.356 25.2159 592.705C23.9583 592.053 22.4735 591.727 20.7614 591.727C19.1553 591.727 17.6402 592.023 16.2159 592.614C14.8068 593.189 13.6553 594.03 12.7614 595.136C11.8674 596.227 11.3826 597.545 11.3068 599.091H5.85227C5.94318 596.652 6.67803 594.515 8.05682 592.682C9.43561 590.833 11.2386 589.394 13.4659 588.364C15.7083 587.333 18.1705 586.818 20.8523 586.818C23.7311 586.818 26.2008 587.402 28.2614 588.568C30.322 589.72 31.9053 591.242 33.0114 593.136C34.1174 595.03 34.6705 597.076 34.6705 599.273C34.6705 601.894 33.9811 604.129 32.6023 605.977C31.2386 607.826 29.3826 609.106 27.0341 609.818V610.182C29.9735 610.667 32.2689 611.917 33.9205 613.932C35.572 615.932 36.3977 618.409 36.3977 621.364C36.3977 623.894 35.7083 626.167 34.3295 628.182C32.9659 630.182 31.1023 631.758 28.7386 632.909C26.375 634.061 23.6856 634.636 20.6705 634.636Z"
                      fill="white"
                    />
                    <path
                      d="M49.3949 805.636C46.7282 805.636 44.3267 805.106 42.1903 804.045C40.054 802.985 38.3419 801.53 37.054 799.682C35.7661 797.833 35.0616 795.727 34.9403 793.364H40.3949C40.607 795.47 41.5616 797.212 43.2585 798.591C44.9706 799.955 47.0161 800.636 49.3949 800.636C51.304 800.636 53.0009 800.189 54.4858 799.295C55.9858 798.402 57.16 797.174 58.0085 795.614C58.8722 794.038 59.304 792.258 59.304 790.273C59.304 788.242 58.857 786.432 57.9631 784.841C57.0843 783.235 55.8722 781.97 54.3267 781.045C52.7813 780.121 51.0161 779.652 49.0312 779.636C47.607 779.621 46.1449 779.841 44.6449 780.295C43.1449 780.735 41.91 781.303 40.9403 782L35.6676 781.364L38.4858 758.455H62.6676V763.455H43.2131L41.5767 777.182H41.8494C42.804 776.424 44.0009 775.795 45.4403 775.295C46.8797 774.795 48.3797 774.545 49.9403 774.545C52.7888 774.545 55.3267 775.227 57.554 776.591C59.7964 777.939 61.554 779.788 62.8267 782.136C64.1146 784.485 64.7585 787.167 64.7585 790.182C64.7585 793.152 64.0919 795.803 62.7585 798.136C61.4403 800.455 59.6222 802.288 57.304 803.636C54.9858 804.97 52.3494 805.636 49.3949 805.636Z"
                      fill="white"
                    />
                  </svg>
                </div>
              </PopoverContent>
            </Popover>
          </div>
          <Slider
            id="betLineSlider"
            defaultValue={[currentBet.linesBet]}
            value={[currentBet.linesBet]}
            step={1}
            min={1}
            max={5}
            onValueChange={handleBetlineChange}
          />
        </div>
        <div className="w-full mt-2">
          <SpinButton onSpin={spin} />
        </div>
      </CardContent>
      <CardFooter>
        <div className="w-full">
          <WalletConnectButton
            connectClassName="w-full"
            disconnectClassName="w-full"
          />
        </div>
      </CardFooter>
    </Card>
  );
};
