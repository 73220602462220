import { getRandomAlphaNumeric } from "@/lib/random";

const DEFAULT_GAME_STATE: IGameContext = {
  gameStatus: "stopped",
  currentBet: {
    spinId: undefined,
    totalTokensBet: 0.0,
    linesBet: 1,
    serverSignature: "",
  },
  previousBet: {
    spinId: undefined,
    secret: "",
    initializationVector: "",
    serverSignature: "",
    totalTokensBet: 0,
    linesBet: 0,
    stops: [],
    multiplier: 0,
    winningSymbolsPerReel: [],
    animationPlayed: false,
  },
  settings: {
    layouts: {},
    symbols: {},
    payTable: {},
    minimumBet: 0,
    maximumBet: 0,
    currentInitializationVector: getRandomAlphaNumeric(128),
    currentSecretKey: getRandomAlphaNumeric(128),
  },
};

const DEFAULT_GAME_DISPATCH_CONTEXT: IGameDispatchContext = {
  placeBet: () => {
    throw new Error("not implemented");
  },
  handleBetResult: () => {
    throw new Error("not implemented");
  },
  updateUserSettings: () => {
    throw new Error("not implementeed");
  },
  updateCurrentBet: () => {
    throw new Error("not implemented");
  },
  updatePreviousBetAnimationPlayed: () => {
    throw new Error("not implemented");
  },
  setGameStatus: () => {
    throw new Error("not implemented");
  },
};

export { DEFAULT_GAME_DISPATCH_CONTEXT, DEFAULT_GAME_STATE };
