import { log, RequestClientOpts, SERVER_CONFIG } from "@/lib/constants";
import { toast } from "sonner";

export interface RequestOpts extends RequestInit {
  path: string;
  responseSerializer?: (response: Response) => Promise<unknown>;
}

const _logRequestDetails = (fullPath: string, opts: RequestOpts) => {
  const logStr = `${opts.method} ${fullPath}${
    opts.body ? `\n${JSON.stringify(opts, null, 2)}` : ""
  }`;
  log(logStr);
};

export class RequestClientError extends Error {
  code: number;
  constructor(code: number, message: string) {
    super(message);
    this.code = code;
  }
}

export const requestClient = (opts: RequestClientOpts = SERVER_CONFIG) => {
  const { host, port, basePath, protocol } = opts;

  const fullPath = `${protocol || ""}${host || ""}${
    port ? ":" + port : ""
  }${basePath}`;

  const getRequestUri = (path: string) => {
    return fullPath + path;
  };

  const _send = async <T extends SlotResponse>(
    opts: RequestOpts
  ): Promise<T> => {
    const { path, ...rest } = opts;
    let data = {} as T;
    rest.headers = {
      ...(rest.headers ?? {}),
      "Content-Type": "application/json",
    };
    try {
      const requestUri = getRequestUri(path);
      _logRequestDetails(requestUri, opts);
      const request = await fetch(requestUri, rest);
      const response = await request.json();
      data = {
        status: request.status,
        ...response,
      };
    } catch (e) {
      console.error(e);
      throw e;
    }
    log(`response data: ${JSON.stringify(data)}`);
    return data;
  };

  const post = async <T extends SlotResponse>(
    { path, ...rest }: RequestOpts = { path: "" }
  ): Promise<T> => {
    return await _send<T>({
      path,
      method: "POST",
      ...rest,
    });
  };

  const put = async <T extends SlotResponse>(
    { path, ...rest }: RequestOpts = { path: "" }
  ) => {
    return await _send<T>({
      path,
      method: "PUT",
      ...rest,
    });
  };

  const get = async <T extends SlotResponse>(
    { path, ...rest }: RequestOpts = { path: "", body: undefined }
  ): Promise<T> => {
    return _send({
      path,
      method: "GET",
      ...rest,
    });
  };

  return {
    post,
    get,
    put,
  };
};
