import React, { FC } from "react";
import { SlotReel } from "./slot.reel";
// import { validateLayoutProps } from "./slot.utils";

export const SlotLayout: FC<{ layouts: { [key: string]: string } }> = ({
  layouts,
}) => {
  return (
    <div className="flex flex-row gap-1 justify-center">
      <SlotReel stopIndex={0} layout={layouts["0"]} />
      <SlotReel stopIndex={1} layout={layouts["1"]} />
      <SlotReel stopIndex={2} layout={layouts["2"]} />
    </div>
  );
};
