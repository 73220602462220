import React from "react";
import { WalletContext } from "./context/wallet";
import { GamingPanel } from "./components/panel";
import { SlotMachine } from "./components/slotMachine";
import { GameContextProvider } from "./context/game/game";
import { AccountContextProvider } from "./context/account/account";
import { Toaster } from "@/ui/sonner";
import { Disclaimer, HowItWorks } from "./components/information";
import { WinDialog } from "./components/winDialog";
import WidgetTerminal from "./components/jup";
import { LegendDrawer } from "./components/legend";

function App() {
  return (
    <div className="container min-h-screen dark p-2 max-w-screen overflow-x-hidden">
      <WalletContext>
        <AccountContextProvider>
          <GameContextProvider>
            <div>
              <WinDialog />
            </div>
            <div className="flex flex-col min-h-screen">
              <div className="flex flex-row flex-wrap-reverse grow max-w-screen justify-center items-center gap-2 md:gap-5">
                <div className="basis-1/3">
                  <GamingPanel />
                </div>
                <div className="basis-1/3">
                  <div className="w-fit p-2">
                    <SlotMachine />
                  </div>
                </div>
              </div>
              <div id="footer" className="flex w-full items-end">
                <HowItWorks />
                <Disclaimer />
                <LegendDrawer />
              </div>
              <WidgetTerminal />
            </div>
          </GameContextProvider>
        </AccountContextProvider>
      </WalletContext>
      <Toaster richColors />
    </div>
  );
}

export default App;
