type GameActions =
  | { type: "set_settings"; payload: GameSettings }
  | { type: "set_current_bet"; payload: Bet }
  | { type: "set_previous_bet"; payload: PreviousBet }
  | { type: "set_game_status"; payload: GameStatus }
  | { type: "set_error_state"; payload: IGameContext };

export const gameStateReducer = (
  state: IGameContext,
  action: GameActions
): IGameContext => {
  const { type, payload } = action;
  if (type === "set_settings") {
    return {
      ...state,
      settings: payload,
    };
  } else if (type === "set_game_status") {
    return {
      ...state,
      gameStatus: payload,
    };
  } else if (type === "set_current_bet") {
    return {
      ...state,
      currentBet: payload,
    };
  } else if (type === "set_previous_bet") {
    return {
      ...state,
      previousBet: payload,
    };
  } else if (type === "set_error_state") {
    return {
      ...state,
      ...payload,
    };
  } else {
    return state;
  }
};
