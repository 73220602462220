import { getRandomAlphaNumeric } from "@/lib/random";
import React, {
  createContext,
  FC,
  PropsWithChildren,
  useCallback,
  useContext,
  useEffect,
  useReducer,
} from "react";
import { gameStateReducer } from "./game.reducer";
import { useAccount, useAccountDispatch } from "../account/account";
import { toast } from "sonner";
import { useWallet } from "@solana/wallet-adapter-react";
import { VersionedTransaction } from "@solana/web3.js";
import { Buffer } from "buffer";
import {
  findImagesOnWinningPayline,
  generateRandomUserSettings,
} from "./game.util";
import { siteClient, spinClient } from "@/lib/constants";
import { getErrorMessage } from "@/lib/errors";
import {
  DEFAULT_GAME_DISPATCH_CONTEXT,
  DEFAULT_GAME_STATE,
} from "./game.defaults";

const GameContext = createContext<IGameContext>(DEFAULT_GAME_STATE);
const GameDispatchContext = createContext<IGameDispatchContext>(
  DEFAULT_GAME_DISPATCH_CONTEXT
);

export const GameContextProvider: FC<PropsWithChildren> = ({ children }) => {
  const [gameState, gameStateDispatch] = useReducer(
    gameStateReducer,
    DEFAULT_GAME_STATE
  );

  const wallet = useWallet();
  const { authorizationToken } = useAccount();
  const { updateUserKillBalance, clearAuthentication } = useAccountDispatch();

  const handleBetResult = useCallback(
    (stops: number[], multiplier: number) => {
      const { currentInitializationVector, currentSecretKey } =
        gameState.settings;

      const previousBet: PreviousBet = {
        ...gameState.currentBet,
        secret: currentSecretKey,
        initializationVector: currentInitializationVector,
        stops,
        multiplier,
        winningSymbolsPerReel: findImagesOnWinningPayline(
          gameState.settings.layouts,
          stops,
          gameState.currentBet.linesBet
        ),
        animationPlayed: false,
      };

      gameStateDispatch({
        type: "set_previous_bet",
        payload: previousBet,
      });
    },
    [gameState]
  );

  const handleError = useCallback(
    (message: string) => {
      const resetState: IGameContext = {
        ...gameState,
        gameStatus: "errored",
        settings: {
          ...gameState.settings,
          ...generateRandomUserSettings(),
        },
      };
      gameStateDispatch({ type: "set_error_state", payload: resetState });

      toast.error(message);
    },
    [gameState]
  );

  const handleFinalizeSpin = useCallback(
    async (spinId: number, serializedTx: Uint8Array) => {
      if (!authorizationToken) {
        handleError("Sign in to play!");
        return;
      }
      try {
        const encodedTx = Buffer.from(serializedTx).toString("base64");
        setGameStatus("confirming");
        const spinFinalizeResponse = await spinClient.finalizeSpin(
          authorizationToken,
          spinId,
          encodedTx
        );

        if (spinFinalizeResponse.status === 200) {
          setGameStatus("stopped");
          handleBetResult(
            spinFinalizeResponse.stops,
            spinFinalizeResponse.multiplier
          );

          await updateUserKillBalance();
          await initializeSpin();
        } else {
          handleError(getErrorMessage(spinFinalizeResponse.error));
        }
      } catch (e) {
        handleError("Unable to sign transaction.");
      }
    },
    [authorizationToken, handleError, updateUserKillBalance, handleBetResult]
  );

  const setGameStatus = useCallback(async (status: GameStatus) => {
    console.log("setting status: ", status);
    gameStateDispatch({ type: "set_game_status", payload: status });
  }, []);

  const placeBet = useCallback(async () => {
    const { settings, currentBet } = gameState;
    const { currentInitializationVector, currentSecretKey } = settings;

    if (!authorizationToken) {
      handleError("User not authorized");
      return;
    }

    if (!currentBet.spinId) {
      initializeSpin();
      handleError("Bet was not properly initialized.");
      clearAuthentication();
      return;
    }

    setGameStatus("starting");
    const response = await spinClient.startSpin(
      authorizationToken,
      currentBet.spinId,
      currentBet.totalTokensBet,
      currentBet.linesBet,
      currentInitializationVector,
      currentSecretKey
    );

    if (response.status === 200) {
      const decodedTx = VersionedTransaction.deserialize(
        Buffer.from(response.encodedTx, "base64").valueOf()
      );
      if (decodedTx && wallet.signTransaction) {
        try {
          const txSignature = await wallet.signTransaction(decodedTx);
          const serializedTx = txSignature.serialize();
          handleFinalizeSpin(response.spinId, serializedTx);
        } catch (e) {
          handleError("User rejected transaction signature");
        }
      }
    } else {
      handleError(getErrorMessage(response.error));
    }
  }, [
    gameState,
    authorizationToken,
    handleError,
    handleFinalizeSpin,
    clearAuthentication,
    wallet,
  ]);

  const updateUserSettings = useCallback(
    async (userSettings: UserSettings) => {
      let baseSettings: SiteSettings | undefined = gameState.settings;
      if (!baseSettings) {
        baseSettings = await siteClient.getSiteConfiguration();
      }
      gameStateDispatch({
        type: "set_settings",
        payload: {
          ...baseSettings,
          ...userSettings,
        },
      });
    },
    [gameState.settings]
  );

  const updateCurrentBet = useCallback(
    async (nextCurrentBet: Partial<Bet>) => {
      const currentBet = gameState.currentBet;
      if (!nextCurrentBet.linesBet && !nextCurrentBet.totalTokensBet) return;

      nextCurrentBet = {
        ...currentBet,
        ...nextCurrentBet,
      };

      gameStateDispatch({
        type: "set_current_bet",
        payload: nextCurrentBet as Bet,
      });
    },
    [gameState]
  );

  const initializeSpin = useCallback(async () => {
    if (!authorizationToken) return;

    const data = await spinClient.initializeSpin(authorizationToken);

    if (data.status === 200) {
      gameStateDispatch({
        type: "set_current_bet",
        payload: {
          ...gameState.currentBet,
          spinId: data.spinId,
          serverSignature: data.signature,
        },
      });
    } else if (data.status === 403) {
      clearAuthentication();
    }
  }, [authorizationToken, gameState]);

  const updatePreviousBetAnimationPlayed = useCallback(async () => {
    gameStateDispatch({
      type: "set_previous_bet",
      payload: { ...gameState.previousBet, animationPlayed: true },
    });
  }, [gameState.previousBet]);

  const getGameConfiguration = useCallback(async () => {
    const gameConfig = await siteClient.getSiteConfiguration();
    if (!gameConfig || gameConfig.error) {
      handleError(getErrorMessage(gameConfig.error));
    }

    gameStateDispatch({
      type: "set_settings",
      payload: {
        ...gameConfig,
        ...generateRandomUserSettings(),
      },
    });
  }, []);

  useEffect(() => {
    getGameConfiguration();
    initializeSpin();
  }, []);

  useEffect(() => {
    initializeSpin();
  }, [authorizationToken]);

  return (
    <GameContext.Provider value={gameState}>
      <GameDispatchContext.Provider
        value={{
          placeBet,
          handleBetResult,
          updateUserSettings,
          updateCurrentBet,
          updatePreviousBetAnimationPlayed,
          setGameStatus,
        }}
      >
        {children}
      </GameDispatchContext.Provider>
    </GameContext.Provider>
  );
};

export const useGame = () => useContext(GameContext);

export const useGameDispatch = () => useContext(GameDispatchContext);
