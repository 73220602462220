import { LAYOUT_IMAGE_MAP } from "@/components/slotMachine";
import { requestClient } from "./requestClient";
import { buildServerConfig } from "@/lib/constants";

export const siteConfigRequestClient = () => {
  const siteClientConfig = buildServerConfig();
  siteClientConfig.basePath += "/site";

  const baseClient = requestClient(siteClientConfig);

  const getSiteConfiguration = async () => {
    const serverData = await baseClient.get<ServerSiteSettings>({
      path: "/configuration",
    });
    return {
      ...serverData,
      symbols: LAYOUT_IMAGE_MAP,
    };
  };

  const getJackpot = async () => {
    return await baseClient.get<{
      jackpot: number;
      error: string;
      status: number;
    }>({
      path: "/jackpot",
    });
  };

  return {
    getSiteConfiguration,
    getJackpot,
  };
};
