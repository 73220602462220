import React, { FC, useEffect, useState } from "react";
import { Dialog, DialogContent, DialogHeader, DialogTitle } from "@/ui/dialog";
import { useGame, useGameDispatch } from "@/context/game";
import SlotCounter from "react-slot-counter";

const TEXT_ARRAY = [
  "HUGE WIN",
  "NICE WIN",
  "LFGGGGGG",
  "YOU DESTROYED THAT ROUND",
  "EASY MONEY",
];
export const WinDialog: FC = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [randomText, setRandomText] = useState(
    TEXT_ARRAY[Math.floor(Math.random() * TEXT_ARRAY.length)]
  );
  const { previousBet } = useGame();
  const { updatePreviousBetAnimationPlayed } = useGameDispatch();
  useEffect(() => {
    if (!previousBet.animationPlayed && previousBet.totalTokensBet) {
      let text = ":/";
      if (previousBet.multiplier) {
        text = TEXT_ARRAY[Math.floor(Math.random() * TEXT_ARRAY.length)];
      }
      setTimeout(() => {
        setIsOpen(true);
        setRandomText(text);
        updatePreviousBetAnimationPlayed();
      }, 2000);
    }
  }, [previousBet]);

  return (
    <Dialog open={isOpen} onOpenChange={setIsOpen}>
      <DialogContent>
        <DialogHeader>
          <DialogTitle className="text-center">{randomText}</DialogTitle>
          <SlotCounter
            charClassName="text-[72px]"
            containerClassName="p-2 text-center"
            separatorClassName="text-[72px]"
            value={previousBet.multiplier + "x"}
            delay={0}
          />
        </DialogHeader>
      </DialogContent>
    </Dialog>
  );
};
