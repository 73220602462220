import { PublicKey } from "@solana/web3.js";
import { type ClassValue, clsx } from "clsx";
import { twMerge } from "tailwind-merge";

export function cn(...inputs: ClassValue[]) {
  return twMerge(clsx(inputs));
}

export const numberFormatter = Intl.NumberFormat("en", {
  useGrouping: true,
  minimumFractionDigits: 6,
});

export const parsePublicKeySafe = (address: unknown) => {
  let result = undefined;
  try {
    if (address && typeof address === "string") result = new PublicKey(address);
  } catch (e) {
    /* empty */
  }
  return result;
};
