import { useWallet } from "@solana/wallet-adapter-react";
import React, { useCallback, useEffect, useState } from "react";

const WidgetTerminal = () => {
  const [isLoaded, setIsLoaded] = useState(false);

  const passthroughWalletContextState = useWallet();

  useEffect(() => {
    if (!window.Jupiter.syncProps) return;
    window.Jupiter.syncProps({ passthroughWalletContextState });
  }, [passthroughWalletContextState.connected]);

  const launchTerminal = useCallback(() => {
    window.Jupiter.init({
      displayMode: "widget",
      widgetStyle: {
        position: "bottom-right",
        size: "small",
      },
      formProps: {
        fixedOutputMint: true,
        swapMode: "ExactInOrOut",
        initialInputMint: "So11111111111111111111111111111111111111112",
        initialOutputMint: "BLXw8gGrwHeDMjEit5TN6VF9UqmYcesFhYejekSoX81d",
      },
      containerClassName: "bg-white",
      enableWalletPassthrough: true,
      passthroughWalletContextState: passthroughWalletContextState,
      endpoint:
        "https://magical-radial-thunder.solana-mainnet.quiknode.pro/44c4370a09470acd8571d8d16d0f3a7aa43714b7",
    });
  }, [passthroughWalletContextState]);

  useEffect(() => {
    let intervalId: NodeJS.Timeout | undefined = undefined;
    if (!isLoaded || !window.Jupiter.init || !intervalId) {
      intervalId = setInterval(() => {
        setIsLoaded(Boolean(window.Jupiter.init));
      }, 500);
    }

    if (intervalId) {
      return () => clearInterval(intervalId);
    }
  }, [isLoaded]);

  useEffect(() => {
    setTimeout(() => {
      if (isLoaded && Boolean(window.Jupiter.init)) {
        launchTerminal();
      }
    }, 200);
  }, [isLoaded, launchTerminal]);

  // To make sure passthrough wallet are synced
  useEffect(() => {
    if (!window.Jupiter.syncProps) return;
    window.Jupiter.syncProps({ passthroughWalletContextState });
  }, [passthroughWalletContextState]);

  return <></>;
};

export default WidgetTerminal;
