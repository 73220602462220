import { authClient } from "@/clients/authorization";
import { siteConfigRequestClient } from "@/clients/configuration";
import { spinRequestClient } from "@/clients/spin";
import { userRequestClient } from "@/clients/user";

export const _DEBUG = 1;

export const log = (data: unknown, level = "info") => {
  let logger = console.log;
  if (level == "error") logger = console.error;

  if (_DEBUG) {
    logger(data);
  }
};

export interface RequestClientOpts {
  protocol?: string;
  host?: string;
  port?: number;
  basePath: string;
}

export const SERVER_CONFIG: RequestClientOpts = {
  basePath: "/api/v1",
};

export const buildServerConfig = () => ({
  ...SERVER_CONFIG,
});

export const spinClient = spinRequestClient();
export const siteClient = siteConfigRequestClient();
export const auth = authClient();
export const user = userRequestClient();
