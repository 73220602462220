import { buildServerConfig } from "@/lib/constants";
import { requestClient } from "./requestClient";

export const authClient = () => {
  const authConfig = buildServerConfig();
  authConfig.basePath += "/auth";

  const baseClient = requestClient(authConfig);

  const sign = async (address: string, signature: string, nonce: string) => {
    return await baseClient.post<SignatureResponse>({
      path: "/sign",
      body: JSON.stringify({ address, signature, nonce }),
    });
  };

  const nonce = async (address: string) => {
    return await baseClient.get<Nonce>({ path: `/nonce?address=${address}` });
  };
  return {
    sign,
    nonce,
  };
};
