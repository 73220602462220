import { useWallet } from "@solana/wallet-adapter-react";
import { WalletDisconnectButton } from "./wallet.disconnect";
import { WalletConnectDropdown } from "./wallet.select";
import React, { FC } from "react";

interface WalletConnectProps {
  connectClassName?: string | undefined;
  disconnectClassName?: string | undefined;
}

const WalletConnectButton: FC<WalletConnectProps> = (props) => {
  const wallet = useWallet();

  if (wallet.connected)
    return <WalletDisconnectButton className={props?.disconnectClassName} />;
  else return <WalletConnectDropdown className={props?.connectClassName} />;
};

export { WalletConnectButton };
