import SOC from "./img1.webp";
import OG from "./img2.webp";
import KDOGE from "./img3.webp";
import HODL from "./img4.webp";
import BOXER from "./img5.webp";
import SNIPER from "./img6.webp";
import ARM_WRESTLE from "./img7.webp";
import BLANK from "./img8.webp";
import JACKPOT from "./jackpot.webp";

export const IMAGE_ARRAY = [
  SOC,
  OG,
  KDOGE,
  HODL,
  BOXER,
  SNIPER,
  ARM_WRESTLE,
  BLANK,
  JACKPOT,
];

export const LAYOUT_IMAGE_MAP = {
  "1": SOC,
  "2": KDOGE,
  "3": HODL,
  "4": BOXER,
  "5": SNIPER,
  "6": ARM_WRESTLE,
  "7": OG,
  "8": JACKPOT,
};

export type IMAGE_KEY = keyof typeof LAYOUT_IMAGE_MAP;
