import { LAYOUT_IMAGE_MAP, type IMAGE_KEY } from "../slotMachine";
type LegendItemProps = {
  image: IMAGE_KEY;
  twoInRow: number;
  threeInRow: number;
};

const LegendItem = ({ image, twoInRow, threeInRow }: LegendItemProps) => {
  return (
    <tr className="text-center">
      <td>
        <img
          className="mx-auto"
          src={LAYOUT_IMAGE_MAP[image]}
          height={48}
          width={48}
        />
      </td>
      <td>{`${image === "8" ? "-" : twoInRow + "x"}`}</td>
      <td>{`${image === "8" ? "JACKPOT" : threeInRow + "x"}`}</td>
    </tr>
  );
};

export { LegendItem };
