import React from "react";
import { useWallet } from "@solana/wallet-adapter-react";
import { Button } from "@/ui/button";
import { Avatar, AvatarImage } from "@/ui/avatar";
import { cn } from "../../lib/utils";
import { useAccountDispatch } from "@/context/account/account";

const WalletDisconnectButton = ({
  className,
}: {
  className: string | undefined;
}) => {
  const { wallet, publicKey, disconnect } = useWallet();
  const { clearAuthentication } = useAccountDispatch();

  const pkey = publicKey?.toString();
  if (!pkey) return <></>;

  const walletString =
    pkey.substring(0, 4) + "..." + pkey.substring(pkey.length - 4);

  const buttonStyles = cn(["py-3", className]);
  const handleDisconnect = () => {
    disconnect();
    clearAuthentication();
  };

  return (
    <Button
      variant={"outline"}
      className={buttonStyles}
      onClick={handleDisconnect}
    >
      <Avatar className="h-5 w-5 rounded-[12px] me-3">
        <AvatarImage src={wallet?.adapter.icon} />
      </Avatar>
      Disconnect: {`${walletString}`}
    </Button>
  );
};

export { WalletDisconnectButton };
