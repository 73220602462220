import { AccountAction, IAccountContext } from "./account.types";
import { EMPTY_ACCOUNT_STATE } from "./account.util";

export const accountReducer = (
  state: IAccountContext,
  action: AccountAction
) => {
  const { type, payload } = action;

  if (type === "set_account") {
    return {
      ...state,
      ...payload,
    };
  } else if (type === "set_field") {
    return {
      ...state,
      [payload.key]: payload.value,
    };
  } else if (type === "set_partial") {
    return {
      ...state,
      ...payload,
    };
  } else if (type === "remove_account") {
    return EMPTY_ACCOUNT_STATE;
  } else {
    throw new Error("Something went wrong.");
  }
};
