import React, { FC, useCallback, useEffect, useMemo, useState } from "react";
import { IMAGE_KEY } from "./slotImages/slot.image-data";
import { useGame } from "@/context/game/game";
import {
  Carousel,
  CarouselApi,
  CarouselContent,
  CarouselItem,
} from "@/ui/carousel";
import Autoplay from "embla-carousel-autoplay";
import { getImageSourceFromKey } from "./slot.utils";
import { ImageCard } from "./slot.image-card";

export const SlotReel: FC<{ layout: string; stopIndex: number }> = ({
  layout,
  stopIndex,
}) => {
  const [isPlaying, setIsPlaying] = useState(false);
  const [carouselApi, setCarouselApi] = useState<CarouselApi>();
  const { gameStatus, previousBet } = useGame();

  const imagesInView = useMemo(() => {
    if (!layout || !previousBet) return;
    const centerImage = previousBet.stops[stopIndex];
    return [centerImage - 1, centerImage, (centerImage + 1) % layout.length];
  }, [previousBet, stopIndex, layout]);

  const isImageGlowing = useCallback(
    (imageIndex: number) => {
      if (
        !imagesInView ||
        !imagesInView.includes(imageIndex) ||
        !previousBet ||
        !previousBet.winningSymbolsPerReel
      )
        return false;
      const winningSymbols = previousBet.winningSymbolsPerReel[stopIndex];
      return winningSymbols[imagesInView.indexOf(imageIndex)];
    },
    [imagesInView, previousBet]
  );

  const handleGameStatus = useCallback(
    (_gameStatus: GameStatus) => {
      if (!carouselApi) return;

      if (
        !isPlaying &&
        (_gameStatus === "starting" || _gameStatus === "confirming")
      ) {
        setIsPlaying(true);
        carouselApi.plugins().autoplay.play();
      } else if (
        isPlaying &&
        (_gameStatus === "errored" || _gameStatus === "stopped")
      ) {
        setIsPlaying(false);
        carouselApi.plugins().autoplay.stop();
        console.log(previousBet);
        if (
          _gameStatus === "stopped" &&
          !previousBet.animationPlayed &&
          previousBet.stops.length
        ) {
          const stopPosition = previousBet.stops[stopIndex] - 1;
          carouselApi.scrollTo(stopPosition);
        }
      }
    },
    [carouselApi, previousBet, carouselApi, isPlaying]
  );

  const handleSettle = useCallback((carouselApi: CarouselApi) => {
    console.log(carouselApi?.slidesInView());
  }, []);

  useEffect(() => {
    console.log("GAME STATUS: ", gameStatus);
    if (carouselApi) carouselApi.on("settle", handleSettle);
    handleGameStatus(gameStatus);
  }, [gameStatus, carouselApi, handleGameStatus]);

  if (!layout || layout.length === 0) {
    return <></>;
  }
  const splitLayout = layout.split("");
  return (
    <div className="rounded-[3px] border p-2 my-2">
      <Carousel
        setApi={setCarouselApi}
        plugins={[
          Autoplay({
            delay: 0,
            playOnInit: false,
            stopOnFocusIn: false,
            stopOnInteraction: false,
            stopOnMouseEnter: false,
          }),
        ]}
        orientation="vertical"
        opts={{
          align: "start",
          loop: true,
          watchDrag: false,
          inViewThreshold: 0.5,
        }}
      >
        <CarouselContent className="h-72 p-0 -mt-0 w-24">
          {splitLayout.map((x, idx) => (
            <CarouselItem className="py-px basis-1/3" key={idx}>
              <ImageCard
                animating={isImageGlowing(idx)}
                src={getImageSourceFromKey(x as IMAGE_KEY)}
              />
            </CarouselItem>
          ))}
        </CarouselContent>
      </Carousel>
    </div>
  );
};
