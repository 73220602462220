import React, { FC } from "react";
import { fixedSquare } from "./slot.utils";

interface ImageCardProps {
  animating: boolean;
  src: string;
}

export const ImageCard: FC<ImageCardProps> = (props) => {
  return (
    <img
      width={"96px"}
      height={"96px"}
      src={props.src}
      className={`object-cover ${fixedSquare} ${
        props.animating ? "border-yellow-400 border animate-pulse" : ""
      }`}
    />
  );
};
