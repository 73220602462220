import { getRandomAlphaNumeric } from "@/lib/random";
const PAYLINE_TABLE = [
  [1, 1, 1],
  [0, 0, 0],
  [2, 2, 2],
  [0, 1, 2],
  [2, 1, 0],
];

const computeStartEndSlice = (
  value: number,
  maxValue: number
): [number, number] => {
  return [value - 1, (value + 2) % maxValue];
};

const computeReelSlice = (reel: string, stopPosition: number) => {
  return reel.substring(...computeStartEndSlice(stopPosition, reel.length));
};

const checkBetLine = (indicies: number[], reelMatrix: string[][]) => {
  const firstSymbol = reelMatrix[0][indicies[0]];
  const secondSymbol = reelMatrix[1][indicies[1]];
  const thirdSymbol = reelMatrix[2][indicies[2]];
  console.log("Pay line: ", indicies);

  console.log(firstSymbol, secondSymbol, thirdSymbol);
  let symbolIndexArray: boolean[] = [false, false, false];
  if (firstSymbol === secondSymbol && secondSymbol === thirdSymbol) {
    symbolIndexArray = [true, true, true];
  } else if (firstSymbol === secondSymbol) {
    symbolIndexArray = [true, true, false];
  } else if (secondSymbol === thirdSymbol) {
    symbolIndexArray = [false, true, true];
  }

  return symbolIndexArray;
};

export const findImagesOnWinningPayline = (
  reels: { [key: string]: string },
  stopPositions: number[],
  betLines: number
): Array<Array<boolean>> => {
  const reelStopMatrix = stopPositions.map((stop, index) =>
    computeReelSlice(reels[index], stop).split("")
  );
  const payLinesPlayed = Math.max(1, Math.min(betLines, 5));
  const payLineMatrix = PAYLINE_TABLE.slice(0, payLinesPlayed);
  const symbolsOnWinningLineGlobalIndex: Array<Array<boolean>> = [
    [false, false, false],
    [false, false, false],
    [false, false, false],
  ];

  for (const stopIndexes of payLineMatrix) {
    const values = checkBetLine(stopIndexes, reelStopMatrix);
    console.log("game.utils :", values);
    for (let i = 0; i < values.length; i++) {
      if (!symbolsOnWinningLineGlobalIndex[i][stopIndexes[i]])
        symbolsOnWinningLineGlobalIndex[i][stopIndexes[i]] = values[i];
    }
  }

  return symbolsOnWinningLineGlobalIndex;
};

export const generateRandomUserSettings = () => ({
  currentInitializationVector: getRandomAlphaNumeric(16),
  currentSecretKey: getRandomAlphaNumeric(32),
});
