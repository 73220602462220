import React, { useCallback } from "react";
import { useWallet } from "@solana/wallet-adapter-react";
import { Select, SelectContent, SelectTrigger, SelectValue } from "@/ui/select";
import { WalletListItem } from "./wallet.item";
import { WalletName } from "@solana/wallet-adapter-base";
import { ReloadIcon } from "@radix-ui/react-icons";
import { cn } from "../../lib/utils";

const WalletConnectDropdown = ({
  className,
}: {
  className: string | undefined;
}) => {
  const { select, wallets, connecting, disconnecting } = useWallet();

  const triggerStyles = cn(["w-[180px]", className]);

  const handleWalletSelect = useCallback(async (name: string) => {
    select(name as WalletName);
  }, []);

  return (
    <Select onValueChange={handleWalletSelect}>
      <SelectTrigger className={triggerStyles}>
        {connecting || disconnecting ? (
          <>
            <ReloadIcon className="animate-spin" /> <p>Connecting...</p>
          </>
        ) : (
          <SelectValue placeholder="connect wallet" />
        )}
      </SelectTrigger>
      <SelectContent>
        {wallets.map((wallet, idx) => (
          <WalletListItem key={idx} adapter={wallet.adapter} />
        ))}
      </SelectContent>
    </Select>
  );
};

export { WalletConnectDropdown };
