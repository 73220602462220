import { useGame } from "@/context/game";
import { FC, useMemo } from "react";
import { LegendItem } from "./legend.item";
import { IMAGE_KEY } from "../slotMachine";

const Legend: FC = (props) => {
  const { settings } = useGame();
  const payTable = useMemo(() => {
    if (!settings || !settings.payTable) return [];

    return Object.entries(settings.payTable);
  }, [settings.payTable]);

  return (
    <table className="table-auto w-full">
      <thead>
        <th>Symbol</th>
        <th>Two in a Row</th>
        <th>Three in a Row</th>
      </thead>
      <tbody>
        {payTable.map((value, idx) => (
          <LegendItem
            key={idx}
            image={value[0] as IMAGE_KEY}
            threeInRow={value[1].three_in_row}
            twoInRow={value[1].two_in_row}
          />
        ))}
      </tbody>
    </table>
  );
};

export { Legend };
